import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Upperbody Mobility`}</p>
    <p>{`Technique Work: Kipping Pullups & Correct Pushup Body Positioning`}</p>
    <p>{`Then,`}</p>
    <p>{`100-Burpee Pullups for time. (RX=Jump to pullup bar 1′ above reach, full
arm extension at bottom)`}</p>
    <p>{`Rest 2:00 then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`250M Ski Erg`}</p>
    <p>{`25-Pushups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      